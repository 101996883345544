export const Mail_Vars = {
    fb_link: "https://www.facebook.com/",
    tw_link: "https://twitter.com/",
    sitename: "C & N Estate Agents",
    contact_email: "contact@sirius.com",
    company_phone: "0123 485 4050",
    available_time: "9am - 5pm ,",
    available_days: " Monday - Friday",
    address: "6 Clapham High Street, Clapham, Dubai SW4 7UT",
    primary_color: "#0DB2B2"
}


export const Site_Vars = {
    default_currency: "AED "
}
export const OFF_PLAN = "new_development"